import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import classNames from 'classnames';

import { SECTIONS } from '@wix/communities-blog-client-common';
import PostListItemHeader from '../../../../common/components/post-list-item-header';
import PostTitle from '../../../../common/components/post-title';
import PostFooter from '../../../../common/components/post-footer';
import PostListItemCover from '../../../../common/components/post-list-item-cover';
import { getFeedColorClassName } from '../../../../common/services/layout-config';
import { HorizontalSeparatorForPostCard } from '../../../../common/components/separator';
import withFeedBorderWidth from '../../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../../common/hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../../../common/hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../../../common/hoc/with-post-font-size';
import withPermissions from '../../../../common/hoc/with-permissions';
import { getSection } from '../../../../common/selectors/section-selectors';
import { getPostActions } from '../../../../common/services/post-actions';
import { getIsMemberAreaInstalled } from '../../../../common/store/communities-context/communities-context-selectors';
import {
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
} from '../../../../common/selectors/app-settings-selectors';
import { getContentAlignmentStyles } from '../../../../common/services/content-alignment-helpers';
import styles from './text-on-image.scss';
import postListItemStyles from '../../../../common/styles/post-list-item.scss';

import PostListItemCategoryLabel from '../../../../common/components/post-list-item-category-label';
import { getIsRTL } from '../../../../common/store/basic-params/basic-params-selectors';
import alignmentStyles from '../../../../common/styles/post-list-item-alignment.scss';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '../../../../common/components/ratings-display';
import {
  getPostRating,
  getPostRatingCount,
} from '../../../../common/store/post-ratings/post-ratings-selector';

export const PostListItemTOIMobile = ({
  type,
  post,
  onLikeClick,
  itemConfig,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  postTitleFontSize,
  postMetadataFontSize,
  isMetadataFooterVisible,
  getPostClassName,
  isMoreButtonEnabled,
  can,
  showCategoryLabel,
  alignment,
  isRTL,
  showPostRating,
  section,
  postRating,
  postRatingCount,
}) => {
  const postLink = `/${post.slug}`;

  const containerClassName = classNames(
    styles.container,
    alignmentStyles[alignment],
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    alignmentStyles[alignment],
    isRTL && alignmentStyles.isRTL,
    'post-list-item-wrapper',
    getPostClassName(
      'overlay-background-color',
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
  );

  const linkClassName = classNames(
    'post-title__text',
    styles.link,
    getPostClassName('link-hashtag-hover-color'),
  );
  const titleClassName = classNames(
    styles.title,
    titleFontClassName,
    getPostClassName('title-font', getFeedColorClassName(type, 'title-color')),
  );
  const headerClassName = classNames(
    styles.header,
    contentFontClassName,
    getPostClassName('description-font'),
    alignmentStyles.headerContainer,
  );
  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
  );
  const titleStyle = { fontSize: postTitleFontSize };
  const lineCount = postTitleFontSize >= 24 ? 3 : 4;

  return (
    <article
      className={containerClassName}
      tabIndex="0"
      style={{ borderWidth }}
      data-hook="post-list-item"
    >
      <PostListItemCover
        post={post}
        postLink={postLink}
        type={type}
        canPlayVideo
        isPublic={can('share', 'post', post)}
        videoClassName={styles.videoEmbed}
        imageClassName={styles.image}
      />
      <div className={contentWrapperClassName}>
        <PostListItemHeader
          className={headerClassName}
          post={post}
          showMoreButton={isMoreButtonEnabled}
          showProfileImage={false}
          style={{ fontSize: postMetadataFontSize }}
          type={type}
        />
        {showCategoryLabel && (
          <PostListItemCategoryLabel
            className={alignmentStyles.categoryLabel}
            post={post}
            postListLayout={type}
          />
        )}
        <div className={alignmentStyles.textAlign}>
          <PostTitle
            type={type}
            title={post.title}
            style={titleStyle}
            className={titleClassName}
            to={postLink}
            linkClassName={linkClassName}
            lineCount={isMetadataFooterVisible ? lineCount : lineCount + 1}
          />
          {showPostRating && postRatingCount ? (
            <RatingsDisplay
              className={classNames(
                styles.rating,
                styles[type],
                postListItemStyles[section],
                alignmentStyles.rating,
                [styles.withTextOnImageStyles],
              )}
              rating={postRating}
              count={postRatingCount}
              layout={RatingsDisplayLayout.with_range}
            />
          ) : null}
        </div>
        {isMetadataFooterVisible && (
          <div style={{ fontSize: postMetadataFontSize }}>
            <HorizontalSeparatorForPostCard
              className={classNames(styles.separator)}
            />
            <PostFooter
              post={post}
              onLikeClick={onLikeClick}
              displayIcons={itemConfig.displayFooterIcons}
              className={footerClassName}
              commentClassName={classNames(
                getPostClassName('link-hashtag-hover-color'),
              )}
            />
          </div>
        )}
      </div>
    </article>
  );
};

PostListItemTOIMobile.propTypes = {
  type: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  section: PropTypes.oneOf(SECTIONS),
  isMoreButtonEnabled: PropTypes.bool,
  showCategoryLabel: PropTypes.bool,
  alignment: PropTypes.string,
  showPostRating: PropTypes.bool,
  postRating: PropTypes.number,
  postRatingCount: PropTypes.number,
};

PostListItemTOIMobile.defaultProps = {
  isMetadataFooterVisible: true,
};

const mapRuntimeToProps = (state, { post, canSee }) => {
  const isRTL = getIsRTL(state);
  const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const showMoreButton = Boolean(postActions.length);

  return {
    showMoreButton,
    section: getSection(state),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    alignment: getContentAlignmentStyles(contentAlignmentMobile, isRTL),
    isRTL,
    postRating: getPostRating(state, post._id),
    postRatingCount: getPostRatingCount(state, post._id),
  };
};

export default flowRight(
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  connect(mapRuntimeToProps),
)(PostListItemTOIMobile);
